
    @import "config.scss";
 
.container {
  width: 49vw;
  padding: 20px 10px;
  scroll-snap-align: start;
  @include for-desktop-up {
    width: 270px;
    max-width: 300px;
  }
}
