
    @import "config.scss";
 
.container {
  color: white;
  padding: 10px 20px;
  z-index: 1;
  background-color: #181818;

  .title {
    border-bottom: 1px solid #9a9a9a;
    padding-bottom: 10px;
  }
  .messages {
    height: 100vh;
    margin-top: 10px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    padding-top: 10px;

    .message {
      display: grid;
      grid-template-columns: 0.02fr 0.98fr;
      align-items: center;
      gap: 4px;
      position: relative;
      word-break: break-word;
      .moderatorBtns {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;

        .options {
          background-color: var(--color-primary);
          max-width: 0;
          overflow: hidden;
          transition: max-width 0.1s;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .showOptions {
          background-color: var(--color-primary);
          padding: 10px;
          max-width: 100px;
          border-radius: 10px;
        }
      }
    }
  }
  .input {
    display: flex;
    position: relative;
    .heart {
      background: transparent;
      border: none;
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .sendMessage {
      background: transparent;
      border: none;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      width: 96% !important;
      border-radius: 30px;
      color: white !important;
      padding-right: 40px;
    }
  }
}
