
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  .grid-container {
    // padding: 0 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
    background-color: var(--color-black);
    justify-items: center;
    gap: 16px;

    @include for-desktop-up {
      gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
      padding-right: 2rem;
    }
  }
}
