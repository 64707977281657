
    @import "config.scss";
 
.text {
  font-size: 0.7rem;

  @include for-desktop-up {
    font-size: 1rem;
  }
}

.modalBody {
  height: 30rem;
  overflow-y: auto;

  @include for-desktop-up {
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 20px;
  }
}

.firstContent {
  padding-inline: 2rem;

  @include for-desktop-up {
    padding-inline: 4rem;
  }
}

.imageReference {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include for-desktop-up {
    flex-direction: row;
  }
}

.image {
  display: flex;
  justify-content: center;
  position: relative;
  height: 25rem;
  margin-bottom: 2rem;

  @include for-desktop-up {
    width: 30%;
  }
}

.indications {
  @include for-desktop-up {
    width: 70%;
    align-self: center;
  }
}

.tableBox {
  padding-inline: 2rem;

  @include for-desktop-up {
    padding-inline: 4rem;
  }
}
