
    @import "config.scss";
 
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-backdrop);
  z-index: var(--z-modal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  height: 40rem;
  background: var(--color-grey);
  max-width: 800px;
  margin: auto;
  border-radius: var(--br-hard);
  position: relative;
  padding: 25px;
  width: 80%;
  overflow-y: scroll;

  @include for-desktop-up {
    width: 45rem;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}

.installmentItem {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.boxSelector {
  border: 1px white solid;
  padding: 10px;
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;
  height: 70px;
  background: var(--color-grey);
  overflow-y: hidden;
  width: 100%;
}

.selectedBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.selectorSection {
  @include for-desktop-up {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
  }
}
