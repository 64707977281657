
    @import "config.scss";
 
.heart1 {
  color: #e74c3c;
  font-size: 2rem;
  animation: zigzag1 3s linear forwards;
  position: absolute;
}
.heart2 {
  color: #e74c3c;
  font-size: 2rem;
  animation: zigzag2 3s linear forwards;
  position: absolute;
}
.heart3 {
  color: #e74c3c;
  font-size: 2rem;
  animation: zigzag3 3s linear forwards;
  position: absolute;
}
.heart4 {
  color: #e74c3c;
  font-size: 2rem;
  animation: zigzag4 3s linear forwards;
  position: absolute;
}
.heart5 {
  color: #e74c3c;
  font-size: 2rem;
  animation: zigzag5 3s linear forwards;
  position: absolute;
}
@keyframes zigzag1 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10vh) translateX(50px);
  }

  50% {
    opacity: 0.2;
    transform: translateY(-50vh) translateX(-50px);
  }
  100% {
    opacity: 0;
    transform: translateY(-70vh);
  }
}
@keyframes zigzag2 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10vh) translateX(-20px);
  }

  60% {
    opacity: 0.2;
    transform: translateY(-50vh) translateX(50px);
  }
  100% {
    opacity: 0;
    transform: translateY(-70vh);
  }
}

@keyframes zigzag3 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10vh) translateX(20px);
  }

  60% {
    opacity: 0.1;
    transform: translateY(-40vh) translateX(80px);
  }
  100% {
    opacity: 0;
    transform: translateY(-75vh);
  }
}
@keyframes zigzag4 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-10vh) translateX(20px);
  }

  40% {
    opacity: 0.2;
    transform: translateY(-40vh) translateX(-90px);
  }
  100% {
    opacity: 0;
    transform: translateY(-60vh);
  }
}
@keyframes zigzag5 {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-10vh) translateX(40px);
  }

  43% {
    opacity: 0.2;
    transform: translateY(-30vh) translateX(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(-50vh);
  }
}
