
    @import "config.scss";
 
.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @include for-desktop-up {
    justify-content: center;
    flex-direction: row;
  }
}
.h1 {
  background-color: var(--color-footer-bg);
  font-size: 10px;
  color: var(--color-white-absolute);
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}
