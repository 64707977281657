
    @import "config.scss";
 
.breadcrumb {
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 24px;
  font-size: 12px;

  .link {
    color: white;
  }
  .item {
    display: flex;
    gap: 10px;
  }
  .chevron {
    color: white;
  }
}
