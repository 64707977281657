
    @import "config.scss";
 
.table {
  //   border-radius: 2em;
  border-spacing: 0;
  border-collapse: separate;
  overflow: hidden;
  margin: auto;
  font-size: clamp(0.5rem, 3vw, 1rem);
  color: var(--color-white-absolute);
  margin-block: 1rem;

  & * {
    // border: 1px solid white;
    text-align: center;
    padding: 0.5rem;

    @include for-desktop-up {
      padding: 1rem;
    }
  }
}
