
    @import "config.scss";
 
.text {
  font-size: 0.7rem;

  @include for-desktop-up {
    font-size: 1rem;
  }
}

.modalBody {
  height: 30rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 20px;
  }
}

.firstContent {
  margin-bottom: 2rem;
  margin-inline: 8vw;
}

.imageReference {
  display: flex;
  justify-content: center;
  position: relative;
  height: clamp(10rem, 10vw, 18rem);
  margin-bottom: 2rem;
  width: clamp(12rem, 33vw, 18rem);
  margin: auto;
}

.tableBox {
  // overflow-y: scroll;
  // height: 15rem;
}

.table {
  //   border-radius: 2em;
  border-spacing: 0;
  border-collapse: separate;
  overflow: hidden;
  margin: auto;
  font-size: clamp(0.5rem, 3vw, 1rem);
  color: var(--color-white-absolute);

  & * {
    // border: 1px solid white;
    padding: 20px;
    text-align: center;
  }
}
