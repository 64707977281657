
    @import "config.scss";
 
.modal {
  background-color: var(--color-grey);
  position: relative;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 20rem;
  margin: auto;
  min-height: 18rem;
  justify-content: flex-end;
}

.containerText {
  display: flex;
  flex: 1;
  align-items: center;
}

.close {
  position: absolute !important;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  display: flex;
  width: 40px !important;
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.text {
  padding-top: 1rem;
}
