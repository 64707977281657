
    @import "config.scss";
 
.container {
  padding-inline: 1rem;
}

.isModal {
  border-radius: var(--br-hard);
}
.productGalleryCardDesktop {
  max-width: 100rem;
  z-index: var(--z-content);
  gap: 20px;
  display: flex;
  align-content: center;
  border-radius: var(--br-hard);
  flex-direction: column;

  @include for-desktop-up {
    max-height: 600px;
    flex-direction: row;
    border-bottom: 0;
  }

  .image {
    position: relative;
    .img {
      position: relative;
      width: 130px;
      height: 130px;

      @include for-desktop-up {
        width: clamp(30rem, 45vw, 45rem);
        height: clamp(30rem, 45vw, 45rem);
      }
    }

    .name {
      padding-right: 20px;
    }
  }

  .withoutInterestContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .discount {
    background-color: var(--color-primary);
    z-index: var(--z-content-top);
    position: absolute;
    top: -2px;
    left: -2px;
    border-radius: 10px;
    padding: 10px;
  }
}

.isBackgrounded {
  background-color: var(--color-grey);
}
