
    @import "config.scss";
 
.container {
  background-color: #181818;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-inline: 20px;
  text-align: center;
  height: 100%;
  .form {
    display: flex;
    flex-direction: column;
  }
}
