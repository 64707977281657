
    @import "config.scss";
 
.container {
  background-color: var(--color-white-absolute);
  border-radius: 50px;
  width: 3rem;
  height: 3rem;
  padding: 0.8rem;
  position: relative;
}
