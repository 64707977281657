
    @import "config.scss";
 
.container {
  margin-bottom: 1rem;
  width: 100%;
}

.list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}
