
    @import "config.scss";
 
.form {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  //   border: 1px solid var(--color-primary);
  padding: 2rem;
  border-radius: var(--br-hard);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 1rem;
}
