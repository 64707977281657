
    @import "config.scss";
 
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-backdrop);
  z-index: var(--z-modal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  height: 37rem;
  background: var(--color-grey);
  width: 100%;
  max-width: 800px;
  margin: auto;
  border-radius: var(--br-soft);

  @include for-desktop-up {
    width: 45rem;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
}
