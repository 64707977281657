
    @import "config.scss";
 
.container {
  width: 100%;
  background-color: var(--color-black);

  .roulette {
    position: relative;
    height: 78vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .winner {
      position: absolute;
      background: linear-gradient(180deg, #272827 0%, #181818 100%);

      height: 100%;
      width: 100%;
      z-index: 1;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      .message {
        position: relative;
        width: fit-content;
      }
    }
  }
}
