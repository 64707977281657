
    @import "config.scss";
 
.container {
  color: white;
  background: linear-gradient(
    180deg,
    #18181800 0%,
    #1818184e 10%,
    rgba(24, 24, 24, 0.705) 40%,
    #181818 70%
  );

  z-index: 1;
  position: absolute;
  bottom: -16px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .icon {
    position: absolute;
    top: 12px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid white;
    z-index: 1;
  }
  .messages {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    transition: 1s;
    .message {
      display: flex;
      align-items: center;
      gap: 4px;
      position: relative;
      font-size: 14px;
      word-break: break-word;
      .moderatorBtns {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;

        .options {
          background-color: var(--color-primary);
          max-width: 0;
          overflow: hidden;
          transition: max-width 0.1s;
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .showOptions {
          background-color: var(--color-primary);
          padding: 5px;
          max-width: 100px;
          border-radius: 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .open {
    height: 100px;
  }
  .close {
    margin-top: -17px;
    height: 0px;
  }
  .messages::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .input {
    align-self: center;
    position: relative;
    display: flex;
    background-color: black;
    padding: 13px 55px 10px 5px;
    width: 100vw;
    .heart {
      background: transparent;
      border: none;
      position: absolute;
      right: 25px;
      top: 56%;
      transform: translateY(-50%);
    }
    .sendMessage {
      background: transparent;
      border: none;
      position: absolute;
      right: 75px;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      border-radius: 30px;
      color: white !important;
      padding-right: 40px;
      -webkit-appearance: none;
      appearance: none;
      width: 95%;
    }
  }
}
