
    @import "config.scss";
 
.info {
  width: 100%;
  border-radius: var(--br-soft);
  padding-block: 1rem;
  color: var(--color-white);
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-inline: 1rem;

  @include for-desktop-up {
    overflow-y: scroll;
    width: 50%;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.prices {
  display: flex;
  margin-bottom: 10px;
  margin-top: 25px;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.pricesCity {
  flex-direction: column-reverse;
  gap: 0;
  align-items: flex-start;
}

.withoutColors {
  display: flex;
  gap: 10px;
  background-color: var(--color-primary);
  color: var(--color-text-on-primary);
  margin-bottom: 1rem;
  padding: 4px 7px;
  border-radius: 5px;
}

.addToCartModal {
  z-index: var(--z-content);
  position: sticky;
  bottom: 57%;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.hidden {
  display: none;
}

.addToCartWrapper {
  display: flex;
  gap: 1rem;
  flex: 1;
  align-items: self-end;
}

.linkToDetail {
  margin-top: 1rem;
  text-decoration: underline;
  text-decoration-color: var(--color-primary);
  width: fit-content;
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  appearance: none;
  border: none;
  background: transparent;
  padding: 1.5rem;
  z-index: 111111;
  cursor: pointer;
}

.badge {
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  font-weight: 500;
  height: min-content;
}

.title {
  display: flex;
}

.discount {
  color: white;
  background-color: var(--color-secondary);
  padding: 0.5rem;
  white-space: nowrap;
  height: fit-content;
  border-radius: 0 var(--br-soft) var(--br-soft) 0;
}
