
    @import "config.scss";
 
.modal {
  background-color: #00000080;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.input {
  color: black !important;
  width: 100%;
}
.input:first-child input:-webkit-autofill,
.input:first-child input:-webkit-autofill:hover,
.input:first-child input:-webkit-autofill:focus,
.input:first-child input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  box-shadow: inset 0 0 20px 20px transparent !important;
}

.modalContainer {
  z-index: 1000;
  background-color: #181818;
  min-width: 250px;
  margin-inline: 5vw;
  border-radius: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-inline: 30px;
  padding-block: 45px;
  max-height: 80vh;
  overflow: scroll;

  p {
    margin-bottom: 15px;
    text-align: center;
  }
}
