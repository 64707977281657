
    @import "config.scss";
 
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 35px;

  .chips {
    margin-bottom: '10px';

    @include for-desktop-up {
      width: '65%';
    }
  }

  .galleryHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    min-height: 40px;
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  @include for-desktop-up {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
  }
  .filterChips {
    grid-column-start: 1/3;
  }
}
.containerGallery {
  padding-left: 0px;
  padding-bottom: 70px;
}
@media (max-width: 1440px) {
  .containerGallery {
    padding-inline: 16px;
  }
}
