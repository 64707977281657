
    @import "config.scss";
 
.container {
  margin: 25px 0;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-black-100);
    padding: 10px 0;
    width: 100%;
    cursor: pointer;

    &:active {
      background-color: var(--color-black-100);
    }

    .labelValue {
      display: flex;
      flex-direction: column;

      .label {
        font-weight: 500;
        margin-bottom: 5px;
      }
      .value {
        text-transform: uppercase;
        color: var(--color-black-400);
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    &--row {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
    }
  }
}
