
    @import "config.scss";
 
.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100000000000000000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background: #00000080;
  align-items: center;
}

.modalContainer {
  z-index: 100000;
  background-color: var(--color-grey);
  margin-inline: 5vw;
  border: 2px solid var(--color-white);
  border-radius: 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-inline: 40px;
  padding-block: 45px;

  p {
    margin-bottom: 15px;
    text-align: center;
  }
}

.form {
  height: 100%;
  display: flex !important;
  position: relative;
  align-items: center !important;
  flex-direction: column;
}
